import logo from "../src/media/logo.png";
import newlogo from "../src/media/newlogo.png";
import "./App.css";
import { useState } from "react";
import { color, motion } from "framer-motion";
import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";

const drawerWidth = 240;
const navItems = [
  { nI: "About", nL: "main" },
  { nI: "Skills", nL: "skillsPage" },
  { nI: "Projects", nL: "projectsPage" },
  { nI: "Contact", nL: "contactPage" },
];

function NavBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", fontFamily: "Cursive" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: 100,
        }}
      >
        <Box
          component="img"
          src={newlogo}
          alt="logo"
          sx={{
            width: 51,
            height: 50,
          }}
        />
      </Box>

      <Divider sx={{ borderColor: "white", opacity: "0.4" }} />
      <List>
        {navItems.map((item) => (
          <ListItem
            key={item}
            disablePadding
            sx={{ fontSize: "55px", fontWeight: "bold", textAlign: "center", ":hover":{cursor:"pointer"} }}
          >
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() =>
                document
                  .getElementById(item.nL)
                  ?.scrollIntoView({ behavior: "auto" })
              }
            >
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      fontFamily: "Cursive",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {item.nI}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{ backgroundColor: "navy", fontFamily: "cursive" }}
      >
        <Toolbar>
          <IconButton
            color="white"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon sx={{ color: "white" }} />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
              color: "white",
            }}
          >
            <a href="/" className="logo_link">
              <Box sx={{ display: "flex" }}>
                <Box
                  component="img"
                  src={newlogo}
                  alt="logo"
                  sx={{ width: 90, height: 82, mr:1 }}
                />
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography
                    sx={{
                      fontFamily: "cursive",
                      fontWeight: "bold",
                      fontSize: "larger",
                      color: "white",
                      textDecorationColor: "none",
                    }}
                  >
                    Arundhati Raicar
                  </Typography>
                </Box>
              </Box>
            </a>
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item}
                onClick={() =>
                  document
                    .getElementById(item.nL)
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "cursive",
                    fontWeight: "bold",
                    "&:hover": {
                      textShadow: "5px 0.5px 9px pink",
                    },
                  }}
                >
                  {item.nI}
                </Typography>
              </Button>
            ))}
          </Box>

          <Typography
            variant="h6"
            sx={{
              display: { xs: "block", sm: "none" },
              ml: "auto",
              fontWeight: "bold",
              fontFamily: "Cursive",
            }}
          >
            Arundhati Raicar
          </Typography>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#000053",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default NavBar;

/*
const NavLinks = () => {
  return (
    <>
      <div className="mt-3 ">
        <ul className="flex items-end  mr-6 text-center sm:column-reverse">
          <li className="ml-5 sm:ml-5 mb-2">
            {" "}
            <a
              className="text-shadow-lg shadow-blue-500 hover:cursor-pointer"
              href="#about-section"
            >
              <h2>About</h2>
            </a>
          </li>

          <li className="ml-5 sm:ml-5 mb-2">
            <a
              href="#projects-section"
              className="text-shadow-lg shadow-blue-500 hover:cursor-pointer"
            >
              <h2>Projects</h2>
            </a>
          </li>
          <li className="ml-5  sm:ml-5 mb-2">
            {" "}
            <a
              href="#contact-section"
              className="text-shadow-lg shadow-blue-500 hover:cursor-pointer"
            >
              <h2>Contact</h2>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
function Navabar() {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const handleLinkClick = () => {
    setIsOpen(false); // Close the dropdown menu when a link is clicked
  };

  return (
    <>
      <nav class=" bg-blue-950 flex  z-[20] top-0 fixed w-full  ">
        <div className="md:flex text-white text-xl font-large font-bold font-mono py-2   w-full  ">
          <div className="  font-bold text-2xl flex  " style={{ flex: "1" }}>
            <motion.div
              animate={{ y: 2 }}
              initial={{ y: -100 }}
              transition={{ delay: 0.5 }}
            >
              <a href="/" className="flex text-shadow-lg shadow-blue-500">
                <img src={logo} className="h-10 w-10 ml-3 " />
                <p className="mt-1    ">rundhati Raicar</p>
              </a>
            </motion.div>
          </div>
          <motion.div
            animate={{ y: 2 }}
            initial={{ y: -100 }}
            transition={{ delay: 0.5 }}
          >
            <div className="md:block hidden ">
              <NavLinks />
            </div>
          </motion.div>
          <div className="absolute top-0 right-0">
            <button onClick={handleClick} className="md:hidden">
              {!isOpen ? (
                <h1 className="font-bold m-3 mt-5 text-3xl">☰</h1>
              ) : (
                <h1 className="font-bold top-0 m-3 mt-5 text-3xl">X</h1>
              )}
            </button>
          </div>
        </div>
      </nav>
      {isOpen && (
        <div className="md:hidden bg-blue-950 text-white text-lg font-bold pb-1 mt-14 z-50 fixed top-0  w-full">
          <ul className="   mr-6 ml-9 sm:column-reverse">
            <li className="ml-5 sm:ml-5 mb-2">
              {" "}
              <a
                className="text-shadow-lg shadow-blue-500 hover:cursor-pointer"
                href="#about-section"
                onClick={handleLinkClick}
              >
                <h2>About</h2>
              </a>
            </li>

            <li className="ml-5 sm:ml-5 mb-2">
              <a
                href="#projects-section"
                className="text-shadow-lg shadow-blue-500 hover:cursor-pointer"
              >
                <h2>Projects</h2>
              </a>
            </li>
            <li className="ml-5  sm:ml-5 mb-2">
              {" "}
              <a className="text-shadow-lg shadow-blue-500 hover:cursor-pointer">
                <h2>Contact</h2>
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
}
*/
