import React from "react";
import { Box } from "@mui/material";
import Navbar from "./Navbar";
import Main from "./Main";
import Skills from "./Skills";
import Projects from "./Projects";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import Contact from "./Contact";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0D2ABD", // Define a 'main' color for primary (Navy blue)
    },
    secondary: {
      main: "#FF4081", // Define a 'main' color for secondary (Pink)
    },
    background: {
      default: "#0D2ABD", // Navy blue background for the app
    },
  },
  breakpoints: {
    values: {
      xs: 300,
      sm: 500,
      md: 761,
      lg: 1100,
      xl: 1536,
      customBp: 1800, // Custom breakpoint
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "rgb(13, 42, 189)",
          display: "flex",
          flexDirection: "column", // Components will stack vertically
        }}
      >
        <CssBaseline />
        <Navbar />
        <Main />
        <Skills />
        <Projects />
        <Contact />
      </Box>
    </ThemeProvider>
  );
}

export default App;

