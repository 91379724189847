import { Box } from "@mui/material";
import { Typography, Divider } from "@mui/material";

function Skills() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" }, // Responsive layout
          height: "auto", // Auto height for flexibility
          width: "100%", // Full width
        }}
        id="skillsPage"
      >
        <Box
          sx={{
            flex: 1,
            padding: 2,
            backgroundImage: "linear-gradient(to bottom, #0e0578, #815e94)",
            boxSizing: "border-box", // Ensure padding doesn't overflow
            borderRight: "0.5px solid grey",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              mt: { xs: "15%", sm: "10%" },
              fontSize: { xs: "1.5rem", md: "2rem" },
              // Responsive font size
            }}
            fontFamily="cursive"
            fontWeight="bold"
            color="white"
            style={{ textShadow: "0.1px 0.4px 3px white" }}
          >
            Skills & Certifications
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" }, // Responsive box layout
              justifyContent: "space-between",
              marginTop: "20px",
              gap: 2, // Adds space between items
            }}
          >
            {/* First small box */}
            <Box
              sx={{
                width: { xs: "100%", md: "45%" }, // Responsive width
                height: "auto",
                backgroundColor: "aliceblue",
                padding: "10px",
                boxShadow: "2px 4px 5px grey",
                borderRadius: "10px",
                overflowWrap: "break-word", // Ensure long words don't overflow
                "&:hover": {
                  boxShadow: "2px 4px 5px pink",
                  cursor: "pointer",
                },
              }}
            >
              <Typography
                variant="body1"
                color="#E33F77"
                fontWeight="bolder"
                fontFamily="cursive"
                fontSize="larger"
              >
                Frontend
                <Box
                  component="ul"
                  sx={{
                    color: "#0B5A98",
                    fontFamily: '"fantasy", "Arial", sans-serif',
                    listStyleType: "none",
                    paddingLeft: "8px",
                    fontSize: "large",
                  }}
                >
                  <li>🌐 HTML</li>
                  <li>🖌️ CSS</li>
                  <li>📜 JavaScript</li>
                  <li>⚛️ React</li>
                  <li>🎨 Bootstrap/MUI/Tailwind</li>
                </Box>
              </Typography>
            </Box>

            {/* Second small box */}
            <Box
              sx={{
                width: { xs: "100%", md: "45%" }, // Responsive width
                height: "auto",
                backgroundColor: "aliceblue",
                padding: "10px",
                boxShadow: "2px 2px 5px grey",
                borderRadius: "10px",
                overflowWrap: "break-word",
                "&:hover": {
                  boxShadow: "2px 4px 5px pink",
                  cursor: "pointer",
                },
              }}
            >
              <Typography
                variant="body1"
                color="#E33F77"
                fontWeight="bolder"
                fontFamily="cursive"
                fontSize="larger"
              >
                Backend
                <Box
                  component="ul"
                  sx={{
                    color: "#0B5A98",
                    fontFamily: '"fantasy", "Arial", sans-serif',
                    listStyleType: "none",
                    paddingLeft: "8px",
                    fontSize: "large",
                  }}
                >
                  <li>💎 Ruby on Rails</li>
                  <li>☕ JAVA</li>
                  <li>📂 SQL</li>
                  <li>🐘 PostgreSQL</li>
                  <li>🌳 GIT</li>
                </Box>
              </Typography>
            </Box>
          </Box>

          {/* Third box */}
          <Box
            sx={{
              width: "100%",
              height: "auto", // Auto height to adjust content
              backgroundColor: "aliceblue",
              boxShadow: "2px 2px 5px grey",
              padding: "20px",
              borderRadius: "10px",
              marginTop: "17%",
              overflowWrap: "break-word",
              "&:hover": {
                boxShadow: "2px 4px 5px pink",
                cursor: "pointer",
              },
            }}
          >
            <Typography
              variant="body1"
              color="#E33F77"
              fontWeight="bold"
              fontFamily="cursive"
              fontSize="larger"
            >
              Certifications
              <Box
                component="ul"
                sx={{
                  color: "#0B5A98",
                  fontFamily: '"fantasy", "Arial", sans-serif',
                  paddingLeft: "8px",
                  fontSize: "medium",
                  textDecoration: "none",
                  a: {
                    textDecoration: "none",
                    color: "#0B5A98",
                    textShadow: "0.1px 0.4px 3px pink",
                    "&:hover": {
                      color: "navy", // Change color on hover
                      cursor: "pointer", // Pointer cursor on hover
                    },
                  },
                }}
              >
                 <li>
                  
                  <a
                    href="https://digitalcredential.stanford.edu/check/B1EB65C9F8D40890711347F99CAFCCA800BF407875EBB76B9386087AD86E3023SzN0SzViamlXd1hDNXVZUHRwR3EweTQ2ZzhBMTloaFVlM3hkTEtvNUtJTjRIdjMz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Technical Fundamentals of Gen AI
                  </a>
                 Stanford Online, Dec 24
                </li>
                <li>
                  
                  <a
                    href="https://courses.edx.org/certificates/4429e71ea82542ba8fbd15f4993c43d6"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Introduction to Web Accessibility,
                  </a>
                  edX, May 24
                </li>
                <li>
                  <a
                    href="https://forage-uploads-prod.s3.amazonaws.com/completion-certificates/J.P.%20Morgan/R5iK7HMxJGBgaSbvk_J.P.%20Morgan_cK7MSb4nFZ8kACJdM_1690165211217_completion_certificate.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Forage - JP Morgan Software Engineering Virtual Experience,
                  </a>
                  July '23
                </li>
                <li>
                  <a
                    href="https://www.coursera.org/account/accomplishments/specialization/certificate/EN6N8GLLFY38"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Web Designing for Everybody,
                  </a>
                  Coursera, 2021
                </li>
              </Box>
            </Typography>
          </Box>
        </Box>
        <Divider
          orientation="vertical"
          sx={{
            position: "absolute",
            left: 0,
            height: "calc(100% - 20px)",
            top: "20px",
          }}
          variant="middle" // Optional, controls the thickness of the divider
        />
        <Box
          sx={{
            flex: 1,
            padding: 3,
            backgroundImage: "linear-gradient(to bottom, #0e0578,#815e94)",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              mt: "8%",
              fontSize: { xs: "1.5rem", md: "2rem" }, // Responsive font size
            }}
            fontFamily="cursive"
            fontWeight="bold"
            color="white"
            style={{ textShadow: "0.1px 0.4px 3px white" }}
          >
            Experience
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "auto%", // Auto height to adjust content
              backgroundColor: "aliceblue",
              boxShadow: "2px 2px 5px grey",
              padding: "14px",
              paddingTop: "10px",
              paddingBottom: "15px",
              borderRadius: "10px",
              marginTop: "3%",
              marginBottom: "7%",
              overflowWrap: "break-word",
              "&:hover": {
                boxShadow: "2px 4px 5px pink",
                cursor: "pointer",
              },
            }}
          >
            <Typography
              variant="body1"
              color="#E33F77"
              fontWeight="bolder"
              fontFamily="cursive"
              fontSize="large"
            >
              Junior Software Engineer |{" "}
              <span style={{ color: "#E33F77", fontSize: "medium" }}>
                July'24 - Sep'24
              </span>
            </Typography>
            <Typography
              variant="h6"
              fontFamily="Arial"
              fontSize="medium"
              color="#6A5ACD"
              fontWeight="bold"
              style={{ textShadow: "0.1px 0.4px 1px pink" }}
            >
              AMA Earth Group
            </Typography>
            <Box
              component="ul"
              sx={{
                color: "#0B5A98",
                fontFamily: '"fantasy", "Arial", sans-serif',

                paddingLeft: "8px",
                fontSize: "medium",
              }}
            >
              <li>
                Lead front-end design for a gamified climate action platform
                with 50+ users and 150 total game interactions.
              </li>
              <li>
                Incorporated cutting edge gamification techniques into design
                choices.
              </li>
              <li>
                Built responsive and accessible front-end elements, ensuring
                compatibility across devices.
              </li>
              <li>
                Identified and resolved bugs contributing to a stable
                application.
              </li>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "auto%", // Auto height to adjust content
              backgroundColor: "aliceblue",
              boxShadow: "2px 2px 5px grey",
              padding: "14px",
              paddingTop: "10px",
              paddingBottom: "15px",
              borderRadius: "10px",
              marginTop: "3%",
              overflowWrap: "break-word",
              "&:hover": {
                boxShadow: "2px 4px 5px pink",
                cursor: "pointer",
              },
            }}
          >
            <Typography
              variant="body1"
              color="#E33F77"
              fontWeight="bolder"
              fontFamily="cursive"
              fontSize="large"
            >
              Junior Developer Intern |{" "}
              <span style={{ color: "#E33F77", fontSize: "medium" }}>
                Sep'23 - Dec'23
              </span>
            </Typography>
            <Typography
              variant="h6"
              fontFamily="Arial"
              fontSize="medium"
              color="#6A5ACD"
              fontWeight="bold"
              style={{ textShadow: "0.1px 0.4px 1px pink" }}
            >
              Prifina
            </Typography>
            <Box
              component="ul"
              sx={{
                color: "#0B5A98",
                fontFamily: '"fantasy", "Arial", sans-serif',

                paddingLeft: "8px",
                fontSize: "medium",
              }}
            >
              <li>
                Collaborated with developers and designers to transform Figma
                designs into code.
              </li>
              <li>
                Built the front-end component of the app which would run on
                Prifina’s platform using personalized data.
              </li>
              <li>
                Documented and oversaw the team’s progress with regards to the
                UI design, bugs & discussing the milestone.
              </li>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Skills;
